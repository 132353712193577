// i18next-extract-mark-ns-start google-pay-for-wix
//Payment methods
import {AnchorLink} from 'components/AnchorLink';
import {Background} from 'components/Background';
import {ContactSalesButton} from 'components/ContactSalesButton';
import {Content} from 'components/Content';
import {Partners} from 'components/Partners';
import {Section, SectionActions, SectionHeader, SectionImage} from 'components/Section';
import {SEO} from 'components/SEO';
import {SignUpButton} from 'components/SignUpButton';
import {graphql} from 'gatsby';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';

//Partners
import index2 from 'images/best_payment_gateway_rates.svg';

//Images
import wix_googlepay from 'images/wix_google-pay.svg';

// Clients
import index1 from 'images/payment_gateway.svg';
import index3 from 'images/shopify_payment_gateway.svg';

import React from 'react';
import styled from 'styled-components';
import {List} from 'components/List';
import {IndexImage} from 'components/landings/IndexImage';
import {IndexBackground} from 'components/landings/IndexBackground';
import {BlogLink} from 'components/links/Blog';
import {InternalPageLink} from 'components/links/Pages';

const IndexContent = styled.div`
  padding-top: 90px;
  max-width: 500px;
  @media (max-width: 768px) {
    padding-top: 10px;
  }
`;

const SellingPage: React.FC = () => {
  const {t} = useI18next();
  return (
    <>
      <SEO
        path="google-pay-for-wix"
        title={t('Wix Google Pay')}
        description={t(
          'Add the Wix Google Pay integration to your store to let customers pay with one of the most popular digital wallets in the world. Start here ››'
        )}
      />
      <IndexBackground>
        <Content>
          <Section reverseOnMobile>
            <IndexContent>
              <SectionHeader underline tagName="h1">
                <Trans>Get the Wix Google Pay integration</Trans>
              </SectionHeader>
              <Trans parent="p">
                Google Pay is a popular digital wallet that has hundreds of millions of users,
                making it one of the most used online payment methods among shoppers. Meet consumer
                demand and stay competitive by configuring the Wix Google Pay integration.{' '}
              </Trans>
              <Trans parent="p">
                Use our advanced e-commerce payment gateway to accept and manage the widest range of
                payment methods in a single platform.
              </Trans>
              <SectionActions>
                <SignUpButton variant="light" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
                <ContactSalesButton />
              </SectionActions>
            </IndexContent>
            <IndexImage src={wix_googlepay} alt="Wix google pay" title="Wix google pay" />
          </Section>
        </Content>
      </IndexBackground>
      <Background>
        <Content>
          <Section>
            <SectionImage
              src={index2}
              alt="MONEI Payment Gateway"
              title="MONEI Payment Gateway"
              width={600}
              height={350}
              mobileWidth={280}
            />
            <div>
              <SectionHeader>
                <Trans>Accept GPay payments and many other Wix payment methods</Trans>
              </SectionHeader>
              <Trans parent="p">
                Adding{' '}
                <InternalPageLink slug="payment-methods/google-pay">Google Pay</InternalPageLink> to
                your Wix store is a great place to start, but it’s crucial to accept a range of Wix
                payment methods. Modern consumers call on merchants to offer many payment options
                upon checking out. Including GPay and more{' '}
                <BlogLink slug="digital-wallet-ecommerce">digital wallets</BlogLink> such as{' '}
                <InternalPageLink slug="payment-methods/apple-pay">Apple Pay</InternalPageLink> and{' '}
                <InternalPageLink slug="payment-methods/paypal">PayPal</InternalPageLink>,{' '}
                <InternalPageLink slug="payment-methods/credit-cards">
                  credit and debit cards
                </InternalPageLink>
                , along with <BlogLink slug="local-payment-methods">local payment methods</BlogLink>{' '}
                like <InternalPageLink slug="payment-methods/bizum">Bizum</InternalPageLink> in
                Spain.
              </Trans>
              <Trans parent="p">
                That’s why we combine all major and{' '}
                <BlogLink slug="alternative-payment-methods">alternative payment methods</BlogLink>{' '}
                into one easy-to-use merchant dashboard. This way, you can focus on growing your
                business while reaching more people, bolstering customer satisfaction, and making
                more sales.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Discover MONEI</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
          <Section reverseOnMobile>
            <div>
              <SectionHeader>
                <Trans>Optimize conversions by approving more online payments</Trans>
              </SectionHeader>
              <Trans parent="p">
                Cut down on online{' '}
                <BlogLink slug="online-payment-failure-message">payment failure messages</BlogLink>{' '}
                and avoid lost sales by setting up payment processing rules. With our payments
                orchestration feature, you won’t have to depend on{' '}
                <InternalPageLink slug="redsys-alternative">Redsys</InternalPageLink> alone to
                process transactions. You’ll be able to set a default payment processor and if your
                chosen provider times out, we’ll route the payment to another top-performing one.
                You’ll approve more payments and give customers a{' '}
                <BlogLink slug="frictionless-payments">frictionless payment experience</BlogLink>.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Get MONEI Now</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
            <SectionImage
              src={index1}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={500}
              height={500}
              mobileWidth={280}
            />
          </Section>
          <Section>
            <SectionImage
              src={index3}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={400}
              height={428}
              mobileWidth={180}
            />
            <div>
              <SectionHeader>
                <Trans>Save money on Wix website payment gateway fees</Trans>
              </SectionHeader>
              <Trans parent="p">
                Healthy profit margins lead to a sustainable business. That’s why we’re the only{' '}
                <InternalPageLink slug="wix-payment-gateway">Wix payment provider</InternalPageLink>{' '}
                to offer scaled pricing. As your sales grow, your transaction fees will decrease
                instantaneously.
              </Trans>
              <Trans parent="p">
                Save money to reinvest in building your e-commerce business —{' '}
                <InternalPageLink slug="pricing">view pricing here</InternalPageLink>.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
        </Content>
      </Background>
      <Content fullWidth>
        <Section style={{maxWidth: 900, margin: 'auto'}}>
          <div>
            <SectionHeader underline tagName="h3">
              <Trans>How to add the Wix Google Pay integration to your website</Trans>
            </SectionHeader>
            <Trans parent="p">
              The process to connect your Wix store with our payment gateway is quick and easy.
              Follow these steps to start accepting Wix Google Pay (and more payment methods) today.
            </Trans>
            <List>
              <Trans parent="li">
                <AnchorLink href="https://dashboard.monei.com/?action=signUp">
                  Sign up for MONEI here
                </AnchorLink>
              </Trans>
              <Trans parent="li">
                <AnchorLink href="https://support.monei.com/hc/es-es/articles/360018290877-How-do-I-activate-Google-Pay-">
                  Configure Google Pay payments
                </AnchorLink>{' '}
                in your MONEI dashboard
              </Trans>
              <Trans parent="li">
                Follow the{' '}
                <AnchorLink href="https://docs.monei.com/docs/e-commerce/wix/">
                  Wix integration guide
                </AnchorLink>{' '}
                to connect MONEI to your online store
              </Trans>
              <Trans parent="li">
                Review our{' '}
                <AnchorLink href="https://support.monei.com/hc/en-us/articles/4406122571025-How-to-enable-MONEI-s-payment-gateway-in-Wix-">
                  Wix support article here
                </AnchorLink>
              </Trans>
            </List>
            <Partners />
          </div>
        </Section>
      </Content>
    </>
  );
};

export default SellingPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {ns: {in: ["common", "google-pay-for-wix"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
